import { observable } from 'mobx';

import { BaseData, File, service } from './Base';
import { Client } from './Case';

export interface User extends BaseData {
    username: string;
    avatar: File;
    title?: string;
    bio?: string;
}

export interface Tag extends BaseData {
    name: string;
    model: 'Article' | 'Case' | 'Activity';
}

export interface Highlight extends BaseData {
    title: string;
    summary: string;
    photo: File;
}

export class MetaModel {
    @observable
    onTop = true;

    @observable
    deviceType: 'phone' | 'pad' | 'desktop';

    @observable
    categories: Tag[] = [];

    @observable
    tags: Tag[] = [];

    @observable
    gallery: File[] = [];

    @observable
    staffs: User[] = [];

    @observable
    highlights: Highlight[] = [];

    @observable
    partners: Client[] = [];

    constructor() {
        this.detectScroll();
        self.addEventListener('scroll', this.detectScroll);
        this.getDeviceType();
        self.addEventListener('resize', this.getDeviceType);

        service
            .get<Tag[]>('/categories')
            .then(({ body }) => (this.categories = body));

        service.get<Tag[]>('/tags').then(({ body }) => (this.tags = body));
    }

    detectScroll = () =>
        (this.onTop = document.scrollingElement.scrollTop === 0);

    getDeviceType = () => {
        const { innerWidth } = self;

        return (this.deviceType =
            innerWidth < 577 ? 'phone' : innerWidth < 769 ? 'pad' : 'desktop');
    };

    categoriesOf(model: Tag['model']) {
        return this.categories
            .map(item => item.model === model && item.name)
            .filter(Boolean);
    }

    tagsOf(model: Tag['model']) {
        return this.tags
            .map(item => item.model === model && item.name)
            .filter(Boolean);
    }

    async getGallery() {
        const {
            body: { gallery }
        } = await service.get<{ gallery: File[] }>('/asset');

        return (this.gallery = gallery);
    }

    async getStaffs() {
        const { body } = await service.get<User[]>('/users');

        return (this.staffs = body);
    }

    async getHighlights() {
        const { body } = await service.get<Highlight[]>('/highlights');

        return (this.highlights = body);
    }

    async getPartners() {
        const { body } = await service.get<Client[]>(
            '/clients?partner=true&_sort=updated_at:DESC'
        );
        return (this.partners = body);
    }
}
