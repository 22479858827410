import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { SpinnerBox } from 'boot-cell/source/Prompt/Spinner';
import { CardFooter } from 'boot-cell/source/Content/Card';

import { Image } from 'boot-cell/source/Media/Image';
import { TabNav, ArticleCard, MoreButton } from '../../component';
import { white_board } from '../../image';
import { article, Article, service, meta } from '../../model';

export interface ActivityListProps extends WebCellProps {
    category?: string;
}

@observer
@component({
    tagName: 'article-list',
    renderTarget: 'children'
})
export class ArticleList extends mixin<ActivityListProps>() {
    @attribute
    @watch
    set category(category: string) {
        this.setProps({ category }).then(() => this.loadList(1));
    }

    connectedCallback() {
        this.loadList(1);

        super.connectedCallback();
    }

    loadList(page?: number) {
        return article.getList({ category: this.category, page });
    }

    renderCard({ banner, id, title, created_at }: Article) {
        const date = new Date(created_at);

        return (
            <ArticleCard
                href={'article?id=' + id}
                image={'' + new URL(banner.url, service.baseURI)}
                title={title}
            >
                <CardFooter className="small">
                    发布时间：
                    <time dateTime={date.toJSON()}>
                        {date.toLocaleString()}
                    </time>
                </CardFooter>
            </ArticleCard>
        );
    }

    render() {
        const Category = meta.categoriesOf('Article'),
            { loading, noMore } = article;

        return (
            <>
                <Image background className="banner" src={white_board} />
                <TabNav
                    className="m-0"
                    header="互动观点"
                    path="articles?category="
                    list={['All', ...Category].map(title => ({ title }))}
                    activeIndex={Category.indexOf(this.category) + 1}
                />
                <SpinnerBox className="container p-0 card-deck" cover={loading}>
                    {article.list.map(this.renderCard)}
                </SpinnerBox>
                {noMore ? null : (
                    <MoreButton
                        disabled={loading || noMore}
                        onClick={() => this.loadList()}
                    />
                )}
            </>
        );
    }
}
