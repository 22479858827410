import { createCell } from 'web-cell';
import classNames from 'classnames';
import { formatDate } from 'web-utility/source/date';

import { Activity } from '../../model';

interface ActivityMetaProps extends Activity {
    muted?: boolean;
}

export function ActivityMeta({
    muted = true,
    startTime,
    endTime,
    address,
    organizer
}: ActivityMetaProps) {
    return (
        <ul
            className={classNames(
                'list-unstyled',
                'm-0',
                muted && 'text-muted'
            )}
        >
            <li>
                日期：
                {formatDate(startTime, 'YYYY-MM-DD HH:mm')} ~{' '}
                {formatDate(endTime, 'YYYY-MM-DD HH:mm')}
            </li>
            <li>
                地址：
                {address?.startsWith('http') ? (
                    <a target="_blank" href={address}>
                        {address}
                    </a>
                ) : (
                    address
                )}
            </li>
            <li>主办：{organizer}</li>
        </ul>
    );
}
