import { component, mixin, attribute, watch, createCell } from 'web-cell';
import { observer } from 'mobx-web-cell';

import { PostFrame } from '../../component';
import { showCase } from '../../model';

@observer
@component({
    tagName: 'case-detail',
    renderTarget: 'children'
})
export class CaseDetail extends mixin<{ id: string }>() {
    @attribute
    @watch
    id = '';

    connectedCallback() {
        this.classList.add('d-block', 'bg-white');

        super.connectedCallback();

        if (this.id) showCase.getOne(+this.id);
    }

    render() {
        const { title, client, tags, content } = showCase.current || {};

        return (
            <PostFrame
                title={title}
                content={content}
                metaList={[
                    `客户：${client?.name}`,
                    `品牌：${client?.brand}`,
                    `服务：${tags?.map(({ name }) => name).join('/')}`
                ]}
            />
        );
    }
}
