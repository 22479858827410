import { formatDate } from 'web-utility/source/date';
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';

export function makeDate(year: number, mouth: number, day: number) {
    return formatDate(new Date(year, mouth, day), 'YYYY-MM-DD');
}

export async function saveImage(box: HTMLElement, name: string) {
    const { width, height } = self.getComputedStyle(box);

    saveAs(
        await toBlob(box, {
            width: parseFloat(width),
            height: parseFloat(height)
        }),
        name + '.png'
    );
}
