import {
    icon_blockchain,
    icon_blockchain_active,
    icon_brand,
    icon_brand_active,
    icon_movie,
    icon_movie_active,
    icon_view,
    icon_view_active
} from '../../image';

export const service = [
    {
        icon: icon_brand,
        icon_active: icon_brand_active,
        name: '品牌服务',
        sub: [
            '品牌调研评估',
            '品牌战略规划',
            '品牌架构梳理',
            '品牌定制名称',
            '品牌原创定位',
            '品牌故事定制',
            '品牌全案推广',
            '品牌年度服务',
            '品牌产品规划',
            '流量化全生态',
            '其它'
        ]
    },
    {
        icon: icon_view,
        icon_active: icon_view_active,
        name: '视觉传达',
        sub: [
            '视觉识别策略',
            'VI系统设计',
            '超级IP设计',
            '产品包装设计',
            '形象画册设计',
            '品牌物料设计',
            '品牌官网设计',
            '形象海报设计',
            '原创手绘设计',
            '产品UI设计',
            '其它'
        ]
    },
    {
        icon: icon_movie,
        icon_active: icon_movie_active,
        name: '互动传播',
        sub: [
            '整合营销传播',
            '广告策略创意',
            '新媒体传播',
            '营销活动策划',
            'APP设计开发',
            'H5界面开发',
            '原创文章定制',
            '公众号全运营',
            '网站内容运营',
            '海外账号运营',
            '其它'
        ]
    },
    {
        icon: icon_blockchain,
        icon_active: icon_blockchain_active,
        name: '区块链孵化',
        sub: [
            '海外基金合规',
            '法律意见书出具',
            '项目代币发行',
            '钱包定制开发',
            '量化软件定制',
            '官网设计制作',
            '白皮书原创撰写',
            '品牌IP化包装',
            '海内外媒体宣发',
            '路演活动策划',
            '原创视频定制',
            '其它'
        ]
    },
    { name: '其它' }
];

export const industry = [
    '餐饮',
    '互联网',
    '区块链',
    '电商',
    '教育',
    '医疗',
    '其它'
];

export const client = ['企业', '个人', '第三方机构'];
