import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';

import { Image } from 'boot-cell/source/Media/Image';
import { CarouselView, CarouselItem } from 'boot-cell/source/Media/Carousel';
import { SpinnerBox } from 'boot-cell/source/Prompt/Spinner';

import { Section, BrandCard, TabNav, MoreButton } from '../../component';
import { AboutPage } from '../About';
import style from './index.less';
import { service as services } from '../Order/data';
import { meta, showCase, service } from '../../model';

export interface HomePageProps extends WebCellProps {
    section: string;
    category: string;
}

@observer
@component({
    tagName: 'home-page',
    renderTarget: 'children'
})
export class HomePage extends mixin<HomePageProps>() {
    @attribute
    @watch
    set section(section: string) {
        this.setProps({ section }).then(() =>
            this.querySelector('#' + section)?.scrollIntoView({
                behavior: 'smooth'
            })
        );
    }

    @attribute
    @watch
    set category(category: string) {
        this.setProps({ category }).then(() => this.loadList(1));
    }

    loadList(page?: number) {
        return showCase.getList({ category: this.category, page });
    }

    connectedCallback() {
        meta.getGallery(), this.loadList(1);

        if (meta.deviceType === 'phone') meta.getPartners();

        super.connectedCallback();
    }

    serviceHover = ({ target }: MouseEvent) => {
        const image = (target as HTMLElement).querySelector('img')!;
        const {
            src,
            dataset: { hoverImage }
        } = image;

        image.src = hoverImage;
        image.dataset.hoverImage = src;
    };

    renderService = ({ icon, icon_active, name, sub }: typeof services[0]) => (
        <li
            className="list-inline-item m-0 p-1 p-sm-3 col-6 col-md-4"
            onMouseEnter={this.serviceHover}
            onMouseLeave={this.serviceHover}
        >
            <div className={style.service}>
                <img src={icon} data-hover-image={icon_active} />
                <h3 className="h6 mt-3 mb-4">{name}</h3>
                <ul className="list-inline small text-muted">
                    {sub?.map(name =>
                        name !== '其它' ? (
                            <li className="list-inline-item">{name}</li>
                        ) : null
                    )}
                </ul>
            </div>
        </li>
    );

    render({ category }: HomePageProps) {
        const categories = meta.categoriesOf('Case'),
            isPhone = meta.deviceType === 'phone';

        return (
            <>
                <CarouselView controls interval={3}>
                    {meta.gallery.map(({ url }) => {
                        const image = new URL(url, service.baseURI) + '';

                        return isPhone ? (
                            <CarouselItem>
                                <Image
                                    background
                                    style={{ height: '60vh' }}
                                    src={image}
                                />
                            </CarouselItem>
                        ) : (
                            <CarouselItem image={image} />
                        );
                    })}
                </CarouselView>

                <Section
                    id="business"
                    center
                    title="Core Business"
                    summary="以策略、创意、设计、服务为核心的文化传播广告公司"
                >
                    <ul className="list-inline row m-0">
                        {services
                            .filter(({ sub }) => sub)
                            .slice(0, -1)
                            .map(this.renderService)}
                    </ul>
                </Section>

                <section className="bg-white">
                    <SpinnerBox
                        className="container p-0"
                        cover={showCase.loading}
                    >
                        <TabNav
                            className="m-0"
                            header="合作案例"
                            path="?category="
                            list={['All', ...categories].map(title => ({
                                title
                            }))}
                            activeIndex={categories.indexOf(category) + 1}
                        />
                        <ul className="list-unstyled p-0 d-flex justify-content-center justify-content-md-start flex-wrap">
                            {showCase.thisPage.map(
                                ({ banner, client, id, title, tags }) => (
                                    <BrandCard
                                        image={banner?.url || client.logo.url}
                                        href={'case?id=' + id}
                                        title={title}
                                        description={tags
                                            .map(({ name }) => name)
                                            .join('/')}
                                    />
                                )
                            )}
                        </ul>
                        <MoreButton href="cases" title="合作案例" />
                    </SpinnerBox>
                </section>
            </>
        );
    }
}
