import { createCell } from 'web-cell';
import { DropMenu, DropMenuItem } from 'boot-cell/source/Navigator/DropMenu';

export interface SelectProps {
    value: string;
    options: string[];
    onChange?: (event: CustomEvent) => any;
}

export function Select({ value, options, ...rest }: SelectProps) {
    return (
        <DropMenu buttonColor="light" caption={value} {...rest}>
            {options.map(detail => (
                <DropMenuItem
                    onClick={(event: MouseEvent) => {
                        event.preventDefault();

                        event.target.dispatchEvent(
                            new CustomEvent('change', { bubbles: true, detail })
                        );
                    }}
                >
                    {detail}
                </DropMenuItem>
            ))}
        </DropMenu>
    );
}
