import { BaseData, File, BaseFilter, BaseModel } from './Base';
import { Tag, User } from './Meta';

export interface BaseArticle extends BaseData {
    title: string;
    banner?: File;
    category: Tag;
    tags: Tag[];
    editor: User;
    content: string;
}

export interface Post {
    sourceName?: string;
    sourceURL?: string;
}

export type Article = BaseArticle & Post;

export interface ArticleFilter extends BaseFilter {
    title?: string;
    category?: string;
    tags?: string;
}

export class ArticleModel<
    D extends Article = Article,
    F extends ArticleFilter = ArticleFilter
> extends BaseModel<D, F> {
    basePath = '/articles';
    relations = ['category', 'tags'];
    pageSize = 12;

    getList({ title, ...rest }: F = {} as F) {
        return super.getList({
            ...rest,
            title_contains: title
        });
    }
}
