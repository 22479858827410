import { createCell } from 'web-cell';
import { HTMLHyperLinkProps } from 'web-utility/source/DOM-type';
import { CardProps, Card } from 'boot-cell/source/Content/Card';

import style from './index.less';

export function ArticleCard({
    image,
    href,
    title,
    defaultSlot,
    ...rest
}: CardProps & HTMLHyperLinkProps) {
    return (
        <Card
            {...rest}
            className={`shadow-sm mb-4 ${style.card}`}
            image={
                <div
                    className={style.image}
                    style={{ backgroundImage: `url(${image})` }}
                />
            }
            title={
                <a
                    className="stretched-link text-decoration-none text-dark"
                    href={href}
                >
                    {title}
                </a>
            }
        >
            {defaultSlot}
        </Card>
    );
}
