import { WebCellProps, createCell } from 'web-cell';
import { TextColors } from 'boot-cell/source/utility/constant';
import { BGIconProps, FAIcon } from 'boot-cell/source/Reminder/FAIcon';
import { ButtonProps, Button } from 'boot-cell/source/Form/Button';
import classNames from 'classnames';

import style from './index.less';

export interface IconProps extends WebCellProps {
    name: string;
    color?: TextColors;
    size?: number;
}

export function Icon({
    name,
    color = 'dark',
    className,
    size = 1,
    style,
    defaultSlot,
    ...rest
}: IconProps) {
    return (
        <span
            className={classNames(
                'iconfont',
                `icon${name}`,
                color && `text-${color}`,
                className
            )}
            style={{ ...style, fontSize: `${size}rem` }}
            {...rest}
        />
    );
}

export function BGIcon({ size, color, className, type, ...rest }: BGIconProps) {
    return (
        <span
            className={classNames(
                'fa-stack',
                size && 'fa-' + (typeof size === 'number' ? size + 'x' : size),
                color && 'text-' + color,
                className
            )}
        >
            <FAIcon name={type} stack={2} />
            <Icon
                {...rest}
                className="position-relative"
                size={typeof size === 'number' ? size : 1}
                color="primary"
            />
        </span>
    );
}

export type IconButtonProps = ButtonProps & IconProps;

export function IconButton({
    className,
    href,
    name,
    color,
    size,
    defaultSlot,
    ...rest
}: IconButtonProps) {
    const URI = href && new URL(href + '', self.location.href);

    return (
        <Button
            {...rest}
            className={classNames(style.box, className)}
            color="primary"
            href={href}
            onClick={event =>
                (event.currentTarget as HTMLAnchorElement).protocol ===
                    'data:' && event.preventDefault()
            }
        >
            <Icon name={name} color={color} size={size} />

            {!URI || /^http/.test(URI.protocol) ? null : (
                <span className={style.tooltip}>
                    {URI.protocol === 'data:' ? (
                        <img src={href} />
                    ) : (
                        (href + '').split(':').slice(1).join(':')
                    )}
                </span>
            )}
        </Button>
    );
}
