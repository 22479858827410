import { BaseData, BaseModel } from './Base';

export interface Feedback extends BaseData {
    name: string;
    email: string;
    phone: string;
    content: string;
}

export class FeedbackModel extends BaseModel<Feedback> {
    basePath = '/feedbacks';
    relations = [];
}
