import { component, mixin, createCell, Fragment } from 'web-cell';
import { formToJSON } from 'web-utility/source/DOM';

import { Image } from 'boot-cell/source/Media/Image';
import { FAIcon } from 'boot-cell/source/Reminder/FAIcon';
import { FormField } from 'boot-cell/source/Form/FormField';
import { ToggleField } from 'boot-cell/source/Form/ToggleField';
import { Button } from 'boot-cell/source/Form/Button';
import { openDialog } from 'boot-cell/source/Prompt/Dialog';

import { Select } from '../../component/Select';
import { service, industry as industries, client } from './data';
import { order, Order } from '../../model';
import { MacBook_2 } from '../../image';

interface OrderPageState {
    subService: typeof service[0]['sub'];
    service_0: string;
    service_1: string;
    industry: string;
}

@component({
    tagName: 'order-page',
    renderTarget: 'children'
})
export class OrderPage extends mixin<{}, OrderPageState>() {
    state = {
        subService: service[0].sub,
        service_0: service[0].name,
        service_1: service[0].sub[0],
        industry: industries[0]
    };

    changeService(name: string) {
        const { sub } = service.find(item => item.name === name);

        return this.setState({
            subService: sub,
            service_0: name,
            service_1: sub[0]
        });
    }

    createOrder = async (event: Event) => {
        event.preventDefault();

        const { phone, ...data } = formToJSON(event.target as HTMLFormElement),
            { subService, ...state } = this.state;

        await order.create({ phone: phone + '', ...data, ...state } as Order);

        return openDialog(
            <div
                className="rounded bg-white text-center m-auto"
                style={{ maxWidth: '40rem' }}
            >
                <div className="d-flex flex-column justify-content-around p-5">
                    <FAIcon name="check-circle" size={7} color="primary" />

                    <h3 className="my-3">信 息 提 交 成 功</h3>

                    <p className="text-muted">
                        （我们的工作人员将尽快与您取得联系）
                    </p>
                </div>
                <button type="submit" className="w-100 border-top p-3">
                    关闭
                </button>
            </div>
        );
    };

    connectedCallback() {
        this.classList.add('bg-white');

        super.connectedCallback();
    }

    render(_, { subService, service_0, service_1, industry }: OrderPageState) {
        return (
            <>
                <Image background className="banner" src={MacBook_2} />

                <h2 className="text-center pt-5">咨询合作</h2>
                <hr className="d-block mx-auto mb-5" />

                <div className="border-top pt-3">
                    <form
                        className="container mb-0"
                        onSubmit={this.createOrder}
                    >
                        <div className="row">
                            <FormField
                                className="col-12 col-sm-4"
                                label="咨询的业务服务"
                            >
                                <Select
                                    value={service_0}
                                    options={service.map(({ name }) => name)}
                                    onChange={({ detail }) =>
                                        this.changeService(detail)
                                    }
                                />
                            </FormField>
                            <FormField
                                className="col-12 col-sm-4"
                                label="咨询的业务类型"
                            >
                                <Select
                                    value={service_1}
                                    options={subService}
                                    onChange={({ detail }) =>
                                        this.setState({
                                            service_1: detail
                                        })
                                    }
                                />
                            </FormField>
                            <FormField
                                className="col-12 col-sm-4"
                                label="您的行业"
                            >
                                <Select
                                    value={industry}
                                    options={industries}
                                    onChange={({ detail }) =>
                                        this.setState({
                                            industry: detail
                                        })
                                    }
                                />
                            </FormField>

                            <FormField
                                is="textarea"
                                name="requirement"
                                required
                                className="col-12"
                                label="需求描述"
                                placeholder="请具体描述您的需求……"
                            />
                            <section className="col-12 form-inline mb-3">
                                <FormField label="客户类型">
                                    {client.map((name, index) => (
                                        <ToggleField
                                            type="radio"
                                            className="ml-5"
                                            name="client_type"
                                            defaultValue={name}
                                            checked={!index}
                                        >
                                            {name}
                                        </ToggleField>
                                    ))}
                                </FormField>
                            </section>

                            <FormField
                                name="company"
                                required
                                className="col-12 col-sm-4"
                                label="公司名称"
                            />
                            <FormField
                                name="contact"
                                required
                                className="col-12 col-sm-4"
                                label="业务联系人"
                            />
                            <FormField
                                type="tel"
                                name="phone"
                                required
                                className="col-12 col-sm-4"
                                label="您的手机号"
                            />

                            <FormField
                                type="email"
                                name="email"
                                className="col-12 col-sm-4"
                                label="您的邮箱"
                            />
                            <section className="col-12 text-center py-5">
                                <Button
                                    type="submit"
                                    color="primary"
                                    size="lg"
                                    disabled={order.loading}
                                >
                                    提 交 信 息
                                </Button>
                            </section>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
