import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { Image } from 'boot-cell/source/Media/Image';
import { CarouselView, CarouselItem } from 'boot-cell/source/Media/Carousel';

import sectionStyle from '../../component/Section/index.less';
import { ProfileCard, Section, StaffCard } from '../../component';
import style from './index.less';
import { office_2, meeting, building } from '../../image';
import banner from './banner.png';
import { capability } from '../data';
import { User, meta, service } from '../../model';

export interface AboutPageProps extends WebCellProps {
    section?: string;
}

@observer
@component({
    tagName: 'about-page',
    renderTarget: 'children'
})
export class AboutPage extends mixin<AboutPageProps>() {
    @attribute
    @watch
    set section(section: string) {
        this.setProps({ section }).then(
            () =>
                section &&
                this.querySelector(`#${section}`)?.scrollIntoView({
                    behavior: 'smooth'
                })
        );
    }

    connectedCallback() {
        meta.getStaffs(), meta.getPartners();

        super.connectedCallback();
    }

    renderHeader() {
        return (
            <div
                className={`narrow-box row mx-auto mt-5 mb-5 mb-md-0 ${style.header}`}
            >
                <div className="col-12 col-sm-6">
                    <h2 className={`h1 text-uppercase ${sectionStyle.title}`}>
                        About Us
                    </h2>
                    <p className="text-muted my-4">
                        我们是一家专注于品牌包装策划、平面设计推广的创意智造公司，拥有着专业的从业经验和丰富的人脉资源。在工作中，我们秉承品牌即人性的理念，用专业专注专心专属的做事原则服务于品牌，用新思路为品牌打开新方向，用新创意给品牌注入新血液、用新跨界给品牌带来新生态。
                    </p>
                    <p className="text-muted my-4">
                        二拾三度五致力于为企业及客户打造更有独有的品牌形象，精准洞察品牌智造和产品创新的设计传达，拒绝创意流水化、选择价值差异化、秉承匠心传承化、坚持服务持续化。
                    </p>
                </div>
                <Image fluid className="col-12 col-sm-6" src={meeting} />
                <ul
                    className={`list-inline row mx-auto mx-md-0 py-5 bg-primary text-center ${style.count}`}
                >
                    <li className="list-inline-item m-0 col-6 col-md-3 px-5 text-nowrap">
                        <strong>23°5</strong>
                        <div>时代温度</div>
                    </li>
                    <li className="list-inline-item m-0 col-6 col-md-3 px-5 text-nowrap">
                        <strong>360</strong>
                        <strong>度</strong>
                        <div>品牌视角</div>
                    </li>
                    <li className="list-inline-item m-0 col-6 col-md-3 px-5 text-nowrap">
                        <strong>1000</strong>
                        <strong>+</strong>
                        <div>出品沉淀</div>
                    </li>
                    <li className="list-inline-item m-0 col-6 col-md-3 px-5 text-nowrap">
                        <strong>132</strong>
                        <strong>+</strong>
                        <div>覆盖行业</div>
                    </li>
                </ul>
            </div>
        );
    }

    *renderStaffs() {
        const { deviceType, staffs } = meta;
        const isPhone = deviceType === 'phone';

        for (
            let i = 0, first: User, second: User;
            (second = staffs[i + 1]), (first = staffs[i]);
            i += isPhone ? 1 : 2
        )
            yield (
                <CarouselItem>
                    {isPhone ? (
                        <ProfileCard
                            image={first.avatar?.url}
                            title={first.username}
                            subtitle={first.title}
                        >
                            {first.bio}
                        </ProfileCard>
                    ) : (
                        <StaffCard
                            image={first.avatar?.url}
                            name={first.username}
                            title={first.title}
                        >
                            {first.bio}
                        </StaffCard>
                    )}
                    {isPhone || !second ? null : (
                        <StaffCard
                            imageColumn="right"
                            image={second.avatar?.url}
                            name={second.username}
                            title={second.title}
                        >
                            {second.bio}
                        </StaffCard>
                    )}
                </CarouselItem>
            );
    }

    static renderPartners() {
        const { partners } = meta;

        return (
            <Section center title="Our partners">
                <ul className="list-unstyled row mx-0 mt-5">
                    {partners.map(({ name, logo }) => (
                        <li className="col-3 my-3">
                            <Image
                                className={style.partner}
                                alt={name}
                                src={new URL(logo?.url, service.baseURI) + ''}
                            />
                        </li>
                    ))}
                </ul>
            </Section>
        );
    }

    render() {
        return (
            <>
                <Image background className="banner" src={office_2} />

                <section className="pt-5 pb-5 pb-md-0 bg-white">
                    {this.renderHeader()}
                </section>

                <Image
                    className="d-flex align-items-center"
                    background
                    src={building}
                >
                    <CarouselView className="narrow-box w-100" controls>
                        {[...this.renderStaffs()]}
                    </CarouselView>
                </Image>

                <Section
                    center
                    title="Why choose us?"
                    summary="we're the most valuable and experienced team in the world."
                >
                    <ul className="list-unstyled row justify-content-center m-0">
                        {capability.map(({ icon, title, summary }) => (
                            <li className="col-6 col-sm-3 d-flex flex-column justify-content-between align-items-center p-3 overflow-hidden">
                                <img className="w-50 my-3" src={icon} />
                                <h3 className="h6">{title}</h3>
                                <small className="text-muted text-nowrap">
                                    {summary}
                                </small>
                            </li>
                        ))}
                    </ul>
                </Section>

                <Image fluid src={banner} />

                {AboutPage.renderPartners()}
            </>
        );
    }
}
