import { HTTPClient, request } from 'koajax';
import { observable, computed } from 'mobx';
import { buildURLData } from 'web-utility/source/URL';

const { hostname, protocol } = self.location;

export const service = new HTTPClient({
    baseURI: `${protocol}//${
        hostname === 'localhost' ? 'localhost:1337' : 'data.in235.com'
    }`,
    responseType: 'json'
});

export interface BaseData {
    id: number;
    created_at: string;
    updated_at: string;
}

export interface BaseFilter {
    page?: number;
    size?: number;
    [key: string]: number | string | boolean;
}

export abstract class AsyncModel {
    @observable
    loading = false;

    async load<T>(func: (...params: any[]) => Promise<T>) {
        try {
            this.loading = true;

            var result = await func();
        } finally {
            this.loading = false;
        }
        return result;
    }
}

export abstract class BaseModel<
    D extends BaseData,
    F extends BaseFilter = BaseFilter
> extends AsyncModel {
    abstract basePath: string;
    abstract relations: string[];

    @observable
    current: D;

    pageIndex = 0;
    pageSize = 10;

    @observable
    totalCount = -1;

    @observable
    list: D[] = [];

    @computed
    get noMore() {
        return this.isRangeOut();
    }

    @computed
    get thisPage() {
        const { pageIndex, pageSize } = this;
        const start = (pageIndex - 1) * pageSize;

        return this.list.slice(start, start + pageSize);
    }

    isRangeOut(
        page = this.pageIndex,
        size = this.pageSize,
        total = this.totalCount
    ) {
        return total > -1 && page * size >= total;
    }

    getOne(id: number) {
        return this.load(async () => {
            const { body } = await service.get<D>(`${this.basePath}/${id}`);

            return (this.current = body);
        });
    }

    getList(
        {
            page = this.pageIndex + 1,
            size = this.pageSize,
            ...filter
        }: F = {} as F
    ) {
        if (page === 1) this.totalCount = -1;

        if (this.isRangeOut(page - 1, size))
            throw RangeError('Out of Page range');

        const condition = Object.entries(filter).map(([key, value]) => [
            this.relations.includes(key)
                ? typeof value === 'number'
                    ? `${key}._id`
                    : `${key}.name`
                : key,
            value
        ]);

        return this.load(async () => {
            const { body } = await service.get<D[]>(
                    `${this.basePath}?${buildURLData([
                        ...condition,
                        ['_start', (page - 1) * size],
                        ['_limit', size],
                        ['_sort', 'updated_at:DESC']
                    ])}`
                ),
                { body: count } = await request<string>({
                    path: new URL(
                        `${this.basePath}/count?${buildURLData(condition)}`,
                        service.baseURI
                    )
                }).response;

            (this.pageIndex = page),
                (this.pageSize = size),
                (this.totalCount = +count);

            if (page === 1) this.list = body;
            else this.list.push(...body);

            return body;
        });
    }

    create(data: D) {
        return this.load(() => service.post(this.basePath, buildURLData(data)));
    }
}

export interface File extends BaseData {
    url: string;
}
