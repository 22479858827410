import {
    WebCellProps,
    WebCellElement,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { Nav, NavLink } from 'boot-cell/source/Navigator/Nav';

import style from './index.less';

export interface TabNavProps extends WebCellProps {
    header: WebCellElement;
    path: string;
    list: { title: string; name?: string }[];
    activeIndex?: number;
}

@component({
    tagName: 'tab-nav',
    renderTarget: 'children'
})
export class TabNav extends mixin<TabNavProps>() {
    @watch
    header: WebCellElement;

    @attribute
    @watch
    path = '';

    @watch
    list = [];

    @attribute
    @watch
    activeIndex = 0;

    connectedCallback() {
        this.classList.add(
            'py-5',
            'row',
            'align-items-center',
            'text-sm-center'
        );
        super.connectedCallback();
    }

    render({ header, activeIndex, list, path }: TabNavProps) {
        return (
            <>
                <h2 className="col-12 col-sm-5 mb-5 mb-sm-0 pl-2 pl-sm-0">
                    {header}
                </h2>
                <Nav
                    itemMode="masthead"
                    className="col-12 col-sm-7 justify-content-around justify-content-sm-center"
                >
                    {list.map(({ title, name }, index) => (
                        <NavLink
                            className={`px-1 ${style.link}`}
                            href={path + (index ? name || title : '')}
                            active={index === activeIndex}
                            onClick={() => (this.activeIndex = index)}
                        >
                            {title}
                        </NavLink>
                    ))}
                </Nav>
            </>
        );
    }
}
