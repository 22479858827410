import { BaseArticle, Post, ArticleModel, ArticleFilter } from './Article';
import { service } from './Base';

export interface Registration {
    name: string;
    mobilePhone: string;
    company: string;
    position: string;
    activity: Activity;
}

export interface Activity extends BaseArticle, Post {
    startTime: string;
    endTime: string;
    address: string;
    city?: string;
    organizer: string;
    own: boolean;
    registrations: Registration[];
}

export interface ActivityFilter extends ArticleFilter {
    startTime?: string;
    endTime?: string;
    city?: string;
    address?: string;
    title?: string;
    own?: boolean;
}

export class ActivityModel extends ArticleModel<Activity, ActivityFilter> {
    basePath = '/activities';

    getDayList(startTime: string) {
        const end = new Date(startTime);

        end.setDate(end.getDate() + 1);

        return super.getList({
            startTime_gte: new Date(startTime).toJSON(),
            startTime_lt: end.toJSON(),
            own: false,
            page: 1
        });
    }

    getRangeList({
        startTime,
        endTime,
        city,
        address,
        title,
        ...rest
    }: ActivityFilter) {
        return super.getList({
            startTime_gte: new Date(startTime).toJSON(),
            endTime_lt: new Date(endTime).toJSON(),
            city_contains: city,
            address_contains: address,
            title_contains: title,
            own: true,
            ...rest
        });
    }

    register({ mobilePhone, ...data }: Registration) {
        return service.post('/registrations', {
            mobilePhone: mobilePhone + '',
            ...data
        });
    }
}
