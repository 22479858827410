import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { Image } from 'boot-cell/source/Media/Image';
import { SpinnerBox } from 'boot-cell/source/Prompt/Spinner';

import { BrandCard, TabNav, MoreButton } from '../../component';
import { puzzle } from '../../image';
import { showCase, service, meta } from '../../model';

export interface CaseListProps extends WebCellProps {
    category?: string;
}

@observer
@component({
    tagName: 'case-list',
    renderTarget: 'children'
})
export class CaseList extends mixin<CaseListProps>() {
    @attribute
    @watch
    set category(category: string) {
        this.setProps({ category }).then(() => this.loadList(1));
    }

    connectedCallback() {
        this.loadList(1);

        super.connectedCallback();
    }

    loadList(page?: number) {
        return showCase.getList({ category: this.category, page });
    }

    renderList() {
        return (
            <ul className="list-unstyled p-0 container m-auto d-flex justify-content-center  justify-content-md-start flex-wrap">
                {showCase.list.map(({ banner, client, id, title, tags }) => (
                    <BrandCard
                        image={banner?.url || client.logo.url}
                        href={'case?id=' + id}
                        title={title}
                        description={tags.map(({ name }) => name).join('/')}
                    />
                ))}
            </ul>
        );
    }

    render() {
        const Category = meta.categoriesOf('Case'),
            { loading, noMore } = showCase;

        return (
            <>
                <Image background className="banner" src={puzzle} />
                <TabNav
                    className="m-0"
                    header="合作案例"
                    path="cases?category="
                    list={['All', ...Category].map(title => ({
                        title
                    }))}
                    activeIndex={Category.indexOf(this.category) + 1}
                />
                <SpinnerBox className="bg-white" cover={loading}>
                    {this.renderList()}
                </SpinnerBox>
                {noMore ? null : (
                    <MoreButton
                        disabled={loading || noMore}
                        onClick={() => this.loadList()}
                    />
                )}
            </>
        );
    }
}
