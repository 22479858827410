import { WebCellProps, createCell } from 'web-cell';
import classNames from 'classnames';

import style from './index.less';

export interface SectionProps extends WebCellProps {
    center?: boolean;
    summary?: string;
}

export function Section({
    className,
    center,
    title,
    summary,
    defaultSlot,
    ...rest
}: SectionProps) {
    return (
        <section
            className={classNames('bg-white', 'pt-5', className)}
            {...rest}
        >
            <div className="narrow-box py-5">
                <h2
                    className={classNames(
                        style.title,
                        center && style.center,
                        !summary && 'mb-4'
                    )}
                >
                    {title}
                </h2>
                {summary && (
                    <p
                        className={classNames(
                            'text-muted',
                            'font-italic',
                            'small',
                            'mb-5',
                            center && 'text-center'
                        )}
                    >
                        {summary}
                    </p>
                )}
                {defaultSlot}
            </div>
        </section>
    );
}
