import {
    icon_client,
    icon_price,
    icon_project,
    icon_service,
    icon_team,
    icon_value,
    icon_work
} from '../image';

export const head_nav = [
    { title: '首页', english: 'Home', href: '' },
    { title: '关于我们', english: 'About Us', href: 'about' },
    { title: '合作案例', english: 'Cases', href: 'cases' },
    { title: '互动观点', english: 'ViewPoints', href: 'articles' },
    { title: '联系我们', english: 'Contact Us', href: 'contact' }
];

export const foot_nav = [
    { title: '服务案例', href: 'cases' },
    { title: '关于我们', href: 'about' },
    { title: '互动观点', href: 'articles' },
    { title: '联系我们', href: 'contact' }
];

export const capability = [
    {
        icon: icon_project,
        title: '项目能力',
        summary: '一对一团队管控，有条不紊、执行力强'
    },
    {
        icon: icon_team,
        title: '团队实力',
        summary: '八年以上从业资质、专业院校毕业'
    },
    {
        icon: icon_service,
        title: '服务能力',
        summary: '多家世界 500 强企业合作见证'
    },
    {
        icon: icon_price,
        title: '价格体系',
        summary: '根据客户需求量化，标准化价格体系'
    },
    {
        icon: icon_work,
        title: '工作标准',
        summary: '以提升客户“品牌竞争力”为最终诉求'
    },
    {
        icon: icon_value,
        title: '成果价值',
        summary: '品牌风格统一，企业可持续化沿用'
    },
    {
        icon: icon_client,
        title: '客户利益',
        summary: '有效降低人力、物力成本，提高效率、性价比高'
    }
];
