import { createCell } from 'web-cell';
import classNames from 'classnames';
import { MediaObjectProps } from 'boot-cell/source/Content/MediaObject';
import { FAIcon } from 'boot-cell/source/Reminder/FAIcon';

import style from './index.less';
import { service } from '../../model/Base';

export interface StaffCardProps extends MediaObjectProps {
    name: string;
}

export function StaffCard({
    className,
    imageColumn = 'left',
    image,
    name,
    title,
    defaultSlot,
    ...rest
}: StaffCardProps) {
    const right = imageColumn !== 'left';

    return (
        <div
            className={classNames(
                style.box,
                right && 'flex-row-reverse',
                className
            )}
            {...rest}
        >
            <img src={new URL(image, service.baseURI) + ''} />
            <div className="flex-fill">
                <header
                    className={classNames(
                        'd-flex',
                        right && 'flex-row-reverse'
                    )}
                >
                    <span className={style.icon}>
                        <FAIcon
                            color="dark"
                            size="lg"
                            name={`quote-${imageColumn}`}
                        />
                    </span>
                    <div
                        className={classNames(
                            'd-inline-block',
                            'mx-2',
                            'text-white',
                            right && 'text-right'
                        )}
                    >
                        <h3 className="h5 mb-1">{name}</h3>
                        <small>{title}</small>
                    </div>
                </header>
                <p className="small text-muted">{defaultSlot}</p>
            </div>
        </div>
    );
}
