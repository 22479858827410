import {
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { formToJSON } from 'web-utility/source/DOM';

import { Form } from 'boot-cell/source/Form/Form';
import { FormField } from 'boot-cell/source/Form/FormField';
import { Button } from 'boot-cell/source/Form/Button';

import { PostFrame } from '../../component';
import { ActivityMeta } from './Meta';
import { activity, Registration, service } from '../../model';

interface ActivityDetailProps {
    id: string;
    formOpen?: boolean;
}

@observer
@component({
    tagName: 'activity-detail',
    renderTarget: 'children'
})
export class ActivityDetail extends mixin<ActivityDetailProps>() {
    @attribute
    @watch
    id = '';

    @attribute
    @watch
    formOpen: boolean;

    connectedCallback() {
        this.classList.add('d-block', 'bg-white');

        super.connectedCallback();

        if (this.id) activity.getOne(+this.id);
    }

    register = async (event: Event) => {
        event.preventDefault();

        const data = formToJSON<Registration>(event.target as HTMLFormElement);

        await activity.register({ activity: +this.id, ...data });

        self.alert('报名成功！');
    };

    renderForm() {
        return (
            <Form
                onSubmit={this.register}
                onReset={() => (this.formOpen = false)}
            >
                <h3 className="p-2 border-bottom">请填写表单</h3>
                <input type="hidden" name="activity" value={this.id} />

                <FormField name="name" label="姓名" required />
                <FormField
                    type="tel"
                    name="mobilePhone"
                    label="手机号"
                    required
                />
                <FormField name="company" label="公司" required />
                <FormField name="position" label="职位" required />

                <Button
                    type="submit"
                    color="primary"
                    className="mr-5"
                    disabled={activity.loading}
                >
                    提 交
                </Button>
                <Button type="reset" color="light">
                    取 消
                </Button>
            </Form>
        );
    }

    render({ formOpen }: ActivityDetailProps) {
        const {
            title,
            content,
            created_at,
            editor,
            sourceURL,
            sourceName,
            banner,
            startTime
        } = activity.current || {};

        return (
            <PostFrame
                title={title}
                content={content}
                metaList={[
                    `发布时间：${new Date(created_at).toLocaleString()}`,
                    editor && `发布者：${editor.username}`,
                    <>
                        来源：
                        <a
                            className="text-muted"
                            target="_blank"
                            href={sourceURL}
                        >
                            {sourceName}
                        </a>
                    </>
                ]}
                header={
                    <>
                        <img
                            className="w-100 my-4"
                            src={new URL(banner?.url, service.baseURI) + ''}
                        />
                        <ActivityMeta muted={false} {...activity.current} />
                    </>
                }
                footer={
                    !formOpen ? (
                        <Button
                            color="primary"
                            disabled={+new Date(startTime) < Date.now()}
                            onClick={() => (this.formOpen = true)}
                        >
                            立 即 报 名
                        </Button>
                    ) : (
                        this.renderForm()
                    )
                }
            />
        );
    }
}
