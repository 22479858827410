import { computed, observable } from 'mobx';
import { History } from 'cell-router/source';

import { MetaModel } from './Meta';
import { ArticleModel, ArticleFilter } from './Article';
import { CaseModel, Case } from './Case';
import { ActivityModel } from './Activity';
import { FeedbackModel } from './Feedback';
import { OrderModel } from './Order';

export * from './Base';
export * from './Meta';
export * from './Article';
export * from './Case';
export * from './Activity';
export * from './Feedback';
export * from './Order';

export const history = new History();
export const meta = new MetaModel();
export const article = new ArticleModel();
export const showCase = new CaseModel();
export const activity = new ActivityModel();
export const feedback = new FeedbackModel();
export const order = new OrderModel();

export class SearchModel {
    pageIndex = 0;
    pageSize = 10;

    @computed
    get loading() {
        return showCase.loading || article.loading;
    }

    @observable
    noMore = false;

    @observable
    list: Case[] = [];

    private loader?: AsyncGenerator<Case>;

    static async *find(keywords: string, page: number) {
        while (true) {
            const list = (
                await Promise.all([
                    page > 1 && showCase.noMore
                        ? null
                        : showCase.getList({ page, title: keywords }),
                    page > 1 && article.noMore
                        ? null
                        : article.getList({ page, title: keywords })
                ])
            )
                .flat<Case>()
                .filter(Boolean)
                .sort(({ created_at: A }, { created_at: B }) =>
                    B.localeCompare(A)
                );

            if (!list[0]) break;

            yield* list;

            page++;
        }
    }

    async getList({ page = this.pageIndex + 1, title }: ArticleFilter = {}) {
        this.pageIndex = page;

        if (!this.loader || page === 1)
            this.loader = SearchModel.find(title, page);

        const list = [];

        while (true) {
            const { done, value } = await this.loader.next();

            if (value) list.push(value);

            this.noMore = done;

            if (done || list.length === this.pageSize) break;
        }

        if (page === 1) this.list = list;
        else this.list.push(...list);

        return list;
    }
}

export const search = new SearchModel();
