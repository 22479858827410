import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { formatDate } from 'web-utility/source/date';

import { Image } from 'boot-cell/source/Media/Image';
import { DropMenu, DropMenuItem } from 'boot-cell/source/Navigator/DropMenu';
import { SpinnerBox } from 'boot-cell/source/Prompt/Spinner';
import { Button } from 'boot-cell/source/Form/Button';

import { ArticleCard } from '../../component';
import { MacBook_0 } from '../../image';
import { Interval, City } from './data';
import { activity, Activity, service, meta } from '../../model';

export interface ActivityListProps extends WebCellProps {
    category?: string;
    startTime?: string;
    endTime?: string;
    city?: string;
}

@observer
@component({
    tagName: 'activity-list',
    renderTarget: 'children'
})
export class ActivityList extends mixin<ActivityListProps>() {
    @attribute
    @watch
    set category(category: string) {
        this.setProps({ category }).then(() => this.loadList(1));
    }

    @attribute
    @watch
    set startTime(startTime: string) {
        this.setProps({ startTime }).then(() => this.loadList(1));
    }

    @attribute
    @watch
    endTime: string;

    @attribute
    @watch
    set city(city: string) {
        this.setProps({ city }).then(() => this.loadList(1));
    }

    connectedCallback() {
        if (!this.category) this.category = null;

        super.connectedCallback();
    }

    loadList(page?: number) {
        return activity.getRangeList({
            category: this.category,
            startTime: this.startTime,
            endTime: this.endTime,
            city: this.city,
            page
        });
    }

    renderFilter() {
        const { category, startTime, endTime, city } = this.props,
            Category = meta.categoriesOf('Activity');

        return (
            <div className="form-inline">
                <DropMenu buttonColor="light" caption={category || '全部活动'}>
                    {['全部活动', ...Category].map((title, index) => (
                        <DropMenuItem
                            href={`activity/list${
                                index ? '?category=' + title : ''
                            }`}
                        >
                            {title}
                        </DropMenuItem>
                    ))}
                </DropMenu>
                <DropMenu
                    buttonColor="light"
                    caption={
                        (
                            Interval.find(
                                ({ start, end }) =>
                                    start === startTime && end === endTime
                            ) || Interval[0]
                        ).title
                    }
                >
                    {Interval.map(({ title, start, end }, index) => (
                        <DropMenuItem
                            href={`activity/list?${
                                index
                                    ? new URLSearchParams({
                                          startTime: start,
                                          endTime: end
                                      })
                                    : ''
                            }`}
                        >
                            {title}
                        </DropMenuItem>
                    ))}
                </DropMenu>
                <DropMenu buttonColor="light" caption={city || City[0]}>
                    {City.map((title, index) => (
                        <DropMenuItem
                            href={`activity/list${
                                index ? '?city=' + title : ''
                            }`}
                        >
                            {title}
                        </DropMenuItem>
                    ))}
                </DropMenu>
            </div>
        );
    }

    renderCard({ startTime, endTime, banner, id, title, city }: Activity) {
        const date = new Date(startTime),
            ended = Date.now() > new Date(endTime).valueOf();

        return (
            <ArticleCard
                href={'activity?id=' + id}
                image={'' + new URL(banner.url, service.baseURI)}
                title={title}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">
                        <time className="mr-2" dateTime={date.toJSON()}>
                            {formatDate(date, 'YYYY.MM.DD')}
                        </time>
                        {city}
                    </small>
                    <Button
                        color={ended ? 'light' : 'primary'}
                        href={'activity?id=' + id}
                    >
                        {ended ? '活 动 结 束' : '进 行 中'}
                    </Button>
                </div>
            </ArticleCard>
        );
    }

    render() {
        const { loading, noMore } = activity;

        return (
            <>
                <Image background className="banner" src={MacBook_0} />

                <header className="d-flex justify-content-around align-items-center flex-column flex-sm-row pt-5 bg-white mb-5">
                    <h2 className="d-sm-none">全球参会指南</h2>
                    <hr className="mb-5 d-sm-none" />
                    {this.renderFilter()}
                </header>
                <SpinnerBox className="container card-deck" cover={loading}>
                    {activity.list.map(this.renderCard)}
                </SpinnerBox>
                <footer className="text-center my-5">
                    <Button
                        color="secondary"
                        outline
                        disabled={loading || noMore}
                        onClick={() => this.loadList()}
                    >
                        {noMore ? '没 有 更 多' : '加 载 更 多'}
                    </Button>
                </footer>
            </>
        );
    }
}
