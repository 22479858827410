import { createCell } from 'web-cell';
import {
    CarouselProps,
    CarouselView,
    CarouselItem
} from 'boot-cell/source/Media/Carousel';
import { Image } from 'boot-cell/source/Media/Image';
import classNames from 'classnames';

import style from './index.less';

interface Picture {
    title: string;
    image: string | URL;
}

export interface ColumnCarouselProps extends CarouselProps {
    column: number;
    itemClass: string;
    list: Picture[];
}

export function ColumnCarousel({
    column,
    list,
    className,
    itemClass,
    ...rest
}: ColumnCarouselProps) {
    const group = list.reduce((group, item, index) => {
            const last = group[group.length - 1];

            if (index % column) last.push(item);
            else group.push([item]);

            return group;
        }, [] as Picture[][]),
        width = `${Math.floor(100 / column) - 1}%`;

    return (
        <CarouselView
            className={classNames('column-carousel', 'd-block', className)}
            interval={3}
            {...rest}
        >
            {group.map(list => (
                <CarouselItem>
                    <div className="d-flex justify-content-between">
                        {list.map(({ image, title }) => (
                            <Image
                                className={classNames(style.image, itemClass)}
                                style={{ width }}
                                background
                                src={image + ''}
                                title={title}
                            />
                        ))}
                    </div>
                </CarouselItem>
            ))}
        </CarouselView>
    );
}
