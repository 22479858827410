import {
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';

import { PostFrame } from '../../component';
import style from './Detail.less';
import { article } from '../../model';

@observer
@component({
    tagName: 'article-detail',
    renderTarget: 'children'
})
export class ArticleDetail extends mixin<{ id: string }>() {
    @attribute
    @watch
    id = '';

    connectedCallback() {
        this.classList.add('d-block', 'bg-white', style.root);

        super.connectedCallback();

        if (this.id) article.getOne(+this.id);
    }

    render() {
        const { title, content, created_at, editor, sourceURL, sourceName } =
            article.current || {};

        return (
            <PostFrame
                title={title}
                content={content}
                metaList={[
                    `发布时间：${new Date(created_at).toLocaleString()}`,
                    `发布者：${editor?.username}`,
                    <>
                        来源：
                        <a
                            className="text-muted"
                            target="_blank"
                            href={sourceURL}
                        >
                            {sourceName}
                        </a>
                    </>
                ]}
            />
        );
    }
}
