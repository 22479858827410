import { WebCellProps, createCell } from 'web-cell';
import { HTMLProps } from 'web-utility/source/DOM-type';
import { Embed } from 'boot-cell/source/Media/Embed';

interface AMapProps extends HTMLProps, WebCellProps {
    latitude: number;
    longitude: number;
}

export function createMapURL({ longitude, latitude, title }: AMapProps) {
    return (
        '//m.amap.com/navi/?' +
        new URLSearchParams({
            dest: [longitude, latitude] + '',
            destName: title,
            key: '8325164e247e15eea68b59e89200988b'
        })
    );
}

export function AMap(props: AMapProps) {
    return <Embed is="iframe" src={createMapURL(props)} />;
}

export function AMapLink({ defaultSlot, className, ...props }: AMapProps) {
    if (!props.title) props.title = defaultSlot + '';

    return (
        <address className={className}>
            <a target="_blank" href={createMapURL(props)}>
                {defaultSlot}
            </a>
        </address>
    );
}
