import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { SpinnerBox } from 'boot-cell/source/Prompt/Spinner';
import { Button } from 'boot-cell/source/Form/Button';
import { CardFooter } from 'boot-cell/source/Content/Card';

import { ArticleCard } from '../component';
import { search, Case, service } from '../model';

export interface SearchPageProps extends WebCellProps {
    model: string;
    keywords: string;
}

@observer
@component({
    tagName: 'search-page',
    renderTarget: 'children'
})
export class SearchPage extends mixin<SearchPageProps>() {
    @attribute
    @watch
    set keywords(keywords: string) {
        this.setProps({ keywords }).then(() =>
            search.getList({ page: 1, title: keywords })
        );
    }

    connectedCallback() {
        this.classList.add('bg-white');

        super.connectedCallback();
    }

    renderCard({ created_at, client, id, banner, title }: Case) {
        const date = new Date(created_at);

        return (
            <ArticleCard
                href={`${client ? 'case' : 'article'}?id=${id}`}
                image={
                    '' +
                    new URL(banner?.url || client.logo.url, service.baseURI)
                }
                title={title}
            >
                <CardFooter className="small text-muted">
                    发布时间：
                    <time dateTime={date.toJSON()}>
                        {date.toLocaleString()}
                    </time>
                </CardFooter>
            </ArticleCard>
        );
    }

    render({ keywords }: SearchPageProps) {
        const { loading, list, noMore } = search;

        return (
            <div className="narrow-box">
                <header className="d-flex justify-content-between align-items-center py-5">
                    <h2>搜索：{keywords}</h2>
                </header>
                <SpinnerBox className="container card-deck" cover={loading}>
                    {list.map(this.renderCard)}
                </SpinnerBox>
                <footer className="text-center py-5">
                    <Button
                        color="secondary"
                        outline
                        disabled={loading || noMore}
                        onClick={() => search.getList({ title: keywords })}
                    >
                        {noMore ? '没 有 更 多' : '加 载 更 多'}
                    </Button>
                </footer>
            </div>
        );
    }
}
