import {
    WebCellProps,
    component,
    mixin,
    attribute,
    watch,
    createCell,
    Fragment
} from 'web-cell';
import { observer } from 'mobx-web-cell';
import { formatDate } from 'web-utility/source/date';
import classNames from 'classnames';

import { Image } from 'boot-cell/source/Media/Image';
import { MonthCalendar } from 'boot-cell/source/Calendar/MonthCalendar';
import { Card } from 'boot-cell/source/Content/Card';
import { FAIcon } from 'boot-cell/source/Reminder/FAIcon';
import { openDialog } from 'boot-cell/source/Prompt/Dialog';

import { ShareCard } from './ShareCard';
import { ActivityMeta } from './Meta';

import style from './Calendar.less';
import { KLine } from '../../image';
import { activity, Activity, service } from '../../model';

export interface ActivityCalendarProps extends WebCellProps {
    date?: string;
}

@observer
@component({
    tagName: 'activity-calendar',
    renderTarget: 'children'
})
export class ActivityCalendar extends mixin<ActivityCalendarProps>() {
    @attribute
    @watch
    set date(date: string) {
        this.setProps({ date }).then(() => activity.getDayList(date));
    }

    connectedCallback() {
        if (!this.date) this.date = formatDate(new Date(), 'YYYY-MM-DD');

        super.connectedCallback();
    }

    renderCard = (item: Activity, index: number) => (
        <Card
            className="shadow-sm my-3"
            direction="row"
            image={
                <img
                    className="card-img-top p-sm-3 rounded-0"
                    src={new URL(item.banner.url, service.baseURI) + ''}
                />
            }
            title={
                <div className="d-flex justify-content-between align-items-center">
                    {item.title}
                    <button onClick={() => this.openCard(index)}>
                        <FAIcon name="share" />
                    </button>
                </div>
            }
            text={<ActivityMeta {...item} />}
        />
    );

    openCard(index: number) {
        const current = activity.list[index] || ({} as Activity);

        return openDialog(
            <div className={style.dialog}>
                <button type="submit" className={style.icon}>
                    <FAIcon name="times" color="light" size="lg" />
                </button>
                {current && (
                    <ShareCard
                        image={new URL(current.banner?.url, service.baseURI)}
                        title={current.title}
                        href={self.location.href}
                    >
                        <ActivityMeta {...current} />
                    </ShareCard>
                )}
            </div>
        );
    }

    render({ date }: ActivityCalendarProps) {
        const this_month = new Date().getMonth();

        return (
            <>
                <Image background className="banner" src={KLine} />
                <MonthCalendar
                    className="d-block narrow-box px-sm-5 my-5"
                    date={date}
                    dateTemplate="YYYY 年 MM 月"
                    weekDays={['一', '二', '三', '四', '五', '六', '七']}
                    renderCell={(date: Date) => {
                        const time = formatDate(date, 'YYYY-MM-DD');

                        return (
                            <a
                                className={classNames(
                                    'stretched-link',
                                    date.getMonth() !== this_month &&
                                        'text-secondary'
                                )}
                                title={`${time} 的活动`}
                                href={'activity/calendar?date=' + time}
                            >
                                {date.getDate()}
                            </a>
                        );
                    }}
                />
                <div className="narrow-box px-3 px-sm-5 my-5">
                    {activity.list.map(this.renderCard)}
                </div>
            </>
        );
    }
}
