import { createCell } from 'web-cell';
import classNames from 'classnames';
import { CardProps } from 'boot-cell/source/Content/Card';

import style from './index.less';
import { service } from '../../model/Base';

export type ProfileCardProps = CardProps

export function ProfileCard({
    className,
    image,
    title,
    subtitle,
    defaultSlot,
    ...rest
}: ProfileCardProps) {
    return (
        <div {...rest} className={classNames(style.box, className)}>
            <header className="h-50 d-flex flex-column align-items-center justify-content-around bg-primary">
                <img
                    className={style.avatar}
                    src={new URL(image, service.baseURI) + ''}
                />
                <h3 className="h5">{title}</h3>
                <p>{subtitle}</p>
            </header>
            <p className="p-3 text-muted">{defaultSlot}</p>
        </div>
    );
}
