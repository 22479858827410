import { makeDate } from '../../utility';

const now = new Date();

const year = now.getFullYear(),
    mouth = now.getMonth(),
    day = now.getDate();

export const Interval = [
    { title: '全部时间' },
    {
        title: '本月',
        start: makeDate(year, mouth, 1),
        end: makeDate(year, mouth + 1, 1)
    },
    {
        title: '本周',
        start: makeDate(year, mouth, day - now.getDay()),
        end: makeDate(year, mouth, day - now.getDay() + 7)
    },
    {
        title: '本日',
        start: makeDate(year, mouth, day),
        end: makeDate(year, mouth, day + 1)
    }
];

export const City = ['全部城市', '北京', '上海', '成都'];
