import { WebCellProps, createCell } from 'web-cell';
import { FAIcon } from 'boot-cell/source/Reminder/FAIcon';
import { encodeQRC } from 'boot-cell/source/utility/QRCode';

import style from './ShareCard.less';
import { saveImage } from '../../utility';

export interface ShareCardProps extends WebCellProps {
    image: string | URL;
    href: string;
}

export function ShareCard({ image, title, defaultSlot, href }: ShareCardProps) {
    return (
        <div className={style.box}>
            <img src={image + ''} />
            <h3>{title}</h3>
            <div className="px-4">{defaultSlot}</div>
            <img src={encodeQRC(href)} />
            <button
                type="reset"
                className="small text-muted"
                onClick={({ currentTarget }: MouseEvent) =>
                    saveImage((currentTarget as Element).parentElement, title)
                }
            >
                <FAIcon name="download" /> 保存图片
            </button>
        </div>
    );
}
