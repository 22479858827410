import { component, mixin, on, createCell, Fragment } from 'web-cell';
import { observer } from 'mobx-web-cell';
import { CellRouter } from 'cell-router/source';
import classNames from 'classnames';
import { NavBar } from 'boot-cell/source/Navigator/NavBar';
import { NavLink } from 'boot-cell/source/Navigator/Nav';
import { Icon } from 'boot-cell/source/Reminder/Icon';
import { Image } from 'boot-cell/source/Media/Image';
import { encodeQRC } from 'boot-cell/source/utility/QRCode';

import { IconButton } from '../component';
import { history, meta } from '../model';
import { head_nav, foot_nav } from './data';
import style from './index.less';
import { logo, logo_2, QRC_WMP, QRC_boss } from '../image';

import { HomePage } from './Home';
import { AboutPage } from './About';
import { ContactPage } from './Contact';
import { ArticleDetail, ArticleList } from './Article';
import { CaseDetail, CaseList } from './Case';
import { ActivityDetail, ActivityList, ActivityCalendar } from './Activity';
import { SearchPage } from './Search';
import { OrderPage } from './Order';

@observer
@component({
    tagName: 'page-router',
    renderTarget: 'children'
})
export class PageRouter extends mixin() {
    protected routes = [
        {
            paths: [''],
            component: HomePage
        },
        {
            paths: ['about'],
            component: AboutPage
        },
        {
            paths: ['contact'],
            component: ContactPage
        },
        {
            paths: ['article'],
            component: ArticleDetail
        },
        {
            paths: ['articles'],
            component: ArticleList
        },
        {
            paths: ['case'],
            component: CaseDetail
        },
        {
            paths: ['cases'],
            component: CaseList
        },
        {
            paths: ['activity'],
            component: ActivityDetail
        },
        {
            paths: ['activity/list'],
            component: ActivityList
        },
        {
            paths: ['activity/calendar'],
            component: ActivityCalendar
        },
        {
            paths: ['search'],
            component: SearchPage
        },
        {
            paths: ['order'],
            component: OrderPage
        }
    ];

    get activeIndex() {
        const { path } = history;

        return !path
            ? 0
            : head_nav.findIndex(({ href }) => href && path.startsWith(href));
    }

    @on('click', 'a[href], area[href]')
    handleRoute() {
        setTimeout(() => this.update());
    }

    renderNavLinks() {
        const { activeIndex } = this;

        return head_nav.map(({ title, english, ...item }, index) => {
            const active = index === activeIndex,
                last = head_nav.length === index + 1;

            return (
                <NavLink
                    className={classNames(
                        index ? last && 'mb-5' : 'mt-5',
                        'my-2',
                        'm-md-0',
                        'px-3',
                        'py-4',
                        'py-md-0',
                        'text-center',
                        active && 'text-primary'
                    )}
                    active={active}
                    data-title={english}
                    {...item}
                >
                    {title}
                </NavLink>
            );
        });
    }

    renderQRC() {
        return (
            <div className="col-5 col-sm-3 text-center">
                <div className="row pb-sm-4">
                    <div className="col-6 small">
                        <Image fluid className={style.QRC} src={QRC_WMP} />
                        关注公众号
                    </div>
                    <div className="col-6 small">
                        <Image fluid className={style.QRC} src={QRC_boss} />
                        商务合作咨询
                    </div>
                </div>
            </div>
        );
    }

    renderFooter() {
        return (
            <footer className={`bg-dark pt-5 pb-2 ${style.footer}`}>
                <div className="container-md">
                    <div className="row align-items-end">
                        <div className="col-7 col-sm-9">
                            <div
                                className={`row align-items-center text-primary ${style.brand}`}
                            >
                                <span className="col-12 col-sm-3">
                                    <img src={logo} />
                                </span>
                                <span className="col-12 col-sm-9 mt-3 mt-sm-0">
                                    二拾三度五
                                    <br />
                                    一个有温度的品牌运营制造商
                                </span>
                            </div>
                            <ul className="list-unstyled d-none d-sm-flex flex-fill flex-wrap py-4 mt-5 mb-0">
                                {foot_nav.map(({ href, title }) => (
                                    <li className="mr-md-3 mr-xl-5">
                                        <a
                                            className="h3 text-decoration-none"
                                            href={href}
                                        >
                                            {title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {this.renderQRC()}
                    </div>
                </div>
                <hr className="w-100 border-secondary mt-5 mb-4" />
                <p className="text-center">
                    <span className="mr-3">成都二拾三度五文化传播有限公司</span>
                    <span>蜀 ICP 备 20010847 号</span>
                </p>
                <p className="text-center text-muted">
                    Proudly developed with
                    <a
                        className="mx-1"
                        target="_blank"
                        href="https://web-cell.dev/"
                    >
                        WebCell v2
                    </a>
                    &amp;
                    <a
                        className="mx-1"
                        target="_blank"
                        href="https://bootstrap.web-cell.dev/"
                    >
                        BootCell v1
                    </a>
                    by
                    <a
                        className="mx-1"
                        target="_blank"
                        href="https://ideapp.dev/"
                    >
                        idea2app
                    </a>
                </p>
            </footer>
        );
    }

    render() {
        const { onTop } = meta,
            detail_page = ['article', 'case'].some(route =>
                history.path.startsWith(`${route}?id=`)
            );

        return (
            <>
                <NavBar
                    className={classNames(
                        style.header,
                        !onTop && style.scrolled
                    )}
                    key="main-nav"
                    fixed="top"
                    expand="md"
                    menuAlign="end"
                    background={detail_page ? 'white' : 'dark'}
                    theme={detail_page ? 'light' : 'dark'}
                    brand={
                        <img
                            alt="二拾三度五文化传播"
                            src={detail_page ? logo_2 : logo}
                        />
                    }
                >
                    {this.renderNavLinks()}
                    <Icon
                        className="d-none d-md-inline-block mx-3"
                        size={1.5}
                        name="list-ul"
                    />
                </NavBar>

                <CellRouter history={history} routes={this.routes} />

                <aside className={style.toolbar}>
                    <IconButton
                        className="my-1"
                        name="telephone"
                        href="tel:13550307072"
                    />
                    <IconButton
                        className="my-1"
                        name="qrcode"
                        href={encodeQRC(
                            'https://u.wechat.com/EOvI19AAZUo4l0_kPF2eqkg'
                        )}
                    />
                    <IconButton className="my-1" name="go-top" href="#top" />
                </aside>

                {this.renderFooter()}
            </>
        );
    }
}
