import { createCell, Fragment } from 'web-cell';
import { formToJSON } from 'web-utility/source/DOM';
import { Jumbotron } from 'boot-cell/source/Content/Jumbotron';
import { FormField } from 'boot-cell/source/Form/FormField';
import { Button } from 'boot-cell/source/Form/Button';

import { Address, Section } from '../../component';
import style from './index.less';
import { Feedback, feedback } from '../../model';

async function submit(event: Event) {
    event.preventDefault();

    const { phone, ...data } = formToJSON(event.target as HTMLFormElement);

    await feedback.create({ phone: phone + '', ...data } as Feedback);

    self.alert('我们已收到您的反馈，将会稍候联系您~');
}

export function ContactPage() {
    return (
        <>
            <Jumbotron
                className={`${style.header} text-center text-white m-0`}
                fluid
                title="Contact Information"
                description="我们的目标简单、粗暴、直接，用影响力为品牌添砖加瓦。"
            >
                <div className="row">
                    <Address className="col-12 col-sm-4" icon="telephone">
                        <a className="text-white" href="tel:+2352352352">
                            +235 235 2352
                        </a>
                        <br />
                        <a className="text-white" href="tel:+8613550307072">
                            +86 13550307072
                        </a>
                    </Address>
                    <Address className="col-12 col-sm-4" icon="mail">
                        <a
                            className="text-white"
                            href="mailto:amanda@in235.com"
                        >
                            amanda@in235.com
                        </a>
                    </Address>
                    <Address className="col-12 col-sm-4" icon="marker">
                        <address className="m-0">
                            四川省成都市武侯区天府大道
                            <br />
                            中段 1388 号美年中心 2113
                        </address>
                    </Address>
                </div>
            </Jumbotron>
            <Section center title="Let's get in touch">
                <form className="row" onSubmit={submit}>
                    <FormField
                        className={`col-12 col-sm-6 ${style['text-rows']}`}
                        is="textarea"
                        name="content"
                        required
                        label="您的信息"
                        placeholder="这里输入您的信息"
                    />
                    <div className="col-12 col-sm-6">
                        <FormField
                            type="email"
                            name="email"
                            required
                            label="您的邮箱"
                            placeholder="请输入您的邮箱"
                        />
                        <div className="row align-items-end">
                            <FormField
                                className="col-8"
                                name="name"
                                required
                                label="您的名字"
                                placeholder="请输入您的名字"
                            />
                            <FormField className="col-4" label="">
                                <Button type="submit" block color="primary">
                                    提交
                                </Button>
                            </FormField>
                        </div>
                    </div>
                </form>
            </Section>
        </>
    );
}
