import { WebCellProps, createCell } from 'web-cell';
import type { HTMLHyperLinkProps } from 'web-utility';
import classNames from 'classnames';

import { Icon } from '../Icon';
import styles from './index.less';
import { service } from '../../model/Base';

export interface BrandCardProps extends HTMLHyperLinkProps, WebCellProps {
    image: string;
    description: string;
}

export function BrandCard({
    className,
    style,
    image,
    target,
    href,
    title,
    description,
    defaultSlot,
    ...rest
}: BrandCardProps) {
    return (
        <li
            className={classNames(styles.card, className)}
            style={{
                ...style,
                backgroundImage: `url(${new URL(image, service.baseURI)})`
            }}
            {...rest}
        >
            <a {...{ target, href }}>
                <div>
                    <h3>{title}</h3>
                    <hr />
                    {description}
                </div>
                <Icon name="arrow-right" size={2} color="white" />
            </a>
        </li>
    );
}
