import { createCell } from 'web-cell';
import classNames from 'classnames';
import { ButtonProps, Button } from 'boot-cell/source/Form/Button';

export function MoreButton({ className, defaultSlot, ...rest }: ButtonProps) {
    return (
        <Button
            className={classNames(
                'w-25',
                'mx-auto',
                'my-5',
                'bg-transparent',
                className
            )}
            block
            outline
            color="secondary"
            {...rest}
        >
            {defaultSlot.length ? defaultSlot : 'more →'}
        </Button>
    );
}
