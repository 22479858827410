import { BaseData, BaseModel } from './Base';

export interface Order extends BaseData {
    company: string;
    service_0: string;
    service_1: string;
    industry: string;
    requirement: string;
    client_type: string;
    contact: string;
    phone: string;
    email: string;
}

export class OrderModel extends BaseModel<Order> {
    basePath = '/orders';
    relations = [];
}
