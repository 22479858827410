import { BaseData, File } from './Base';
import { BaseArticle, ArticleFilter, ArticleModel } from './Article';

export interface Client extends BaseData {
    name: string;
    brand: string;
    logo: File;
}

export interface Case extends BaseArticle {
    client: Client;
}

export class CaseModel extends ArticleModel<Case, ArticleFilter> {
    basePath = '/cases';
    pageSize = 9;
}
